import React from 'react'
import xmas2024 from '../../../../images/Christmas2024.jpg'
import { makeStyles } from '@material-ui/core';
// import ImageDisplayCarousel from '../../../customComponents/ImageDisplayCarousel';
// import socialClub from '../../../../images/SocialClub.mp4'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        // marginLeft: '10%',
        // marginRight: '10%',
        width: '100%',
        // backgroundImage: `url(${halloweenBackground})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        // opacity: 0.9,
        // overflow: 'hidden',
        // position: 'relative',
        // color: '#fff',
    },
    textCentered: {
        textAlign: 'center'
    },
    mediaVideoSlide: {
        width: '100%',
        borderRadius: 20,
    },
    media: {
        width: '40%',
        borderRadius: 20,
        // border: '5px solid orange'
    },
    marginLeft: {
        marginLeft: '10%',
    }
}));

export default function Christmas2024(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header>
                <h2 className={classes.textCentered}>Christmas 2024</h2>
            </header>
            <br />

            <h3 className={classes.marginLeft}> Dear Colleagues,</h3>
            <p className={classes.marginLeft}>Wisetek wishes to invite all staff to our annual Christmas Party.</p>
            <p className={classes.marginLeft}>All sign-up sheets will be in the all canteens, please let your Manager/Supervisor know if you have any dietary requirements</p>

            <br />

            <div className={classes.textCentered}>
                <img className={classes.media} src={xmas2024} alt="Wisetek Christmas 2024" />
            </div>



            <br />
            <div className={classes.textCentered}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2461.9917170254153!2d-8.478120539849993!3d51.89761526310984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x484490174b65f941%3A0x3b019d02ca131169!2sRearden&#39;s%20Bar!5e0!3m2!1sen!2sie!4v1732796382052!5m2!1sen!2sie" width="90%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                <br /><br />
                {/* <h4>Thank you for joining Us!</h4> */}
                {/* <br /><br /> */}
            </div>

            <br />
            <br />
            <p className={classes.marginLeft}>We look forward to seeing you all there on the night!🎵🎄💃🕺</p>

            <p className={classes.marginLeft}>Regards,<br />
                Eleanor Lester<br />
                HR Co-Ordinator | Wisetek™<br />
            </p>
            <br />
            {/* <div className={classes.textCentered}>
                <video className={classes.media} src={socialClub}
                    type="video/mp4"
                    // poster={posterEHSWeek}
                    muted
                    autoPlay
                    loop
                    controls
                    controlsList="nodownload">
                    Your browser does not support HTML video.
                </video>
            </div>

            <ImageDisplayCarousel storageRefString='NewsAndAnnouncements/Christmas2023/' headerText='Here are some photos from that night...' getDataNow={props.getDataNow} />

            <p className={classes.marginLeft}>Regards,<br /><br />
                Social Club | Wisetek™<br />
            </p>
            <br /> */}

        </div>
    )
}
