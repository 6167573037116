import React from 'react'
import { makeStyles } from '@material-ui/core';
import LayaHelathcareWebinar from '../../../../images/HealthcareWebinar20241127.mp4'
import IronMountainEmployeePresentation271124 from '../../../../images/pdfsAndDox/IronMountainEmployeePresentation271124.pdf'
import IronMountainFAQNov24 from '../../../../images/pdfsAndDox/IronMountainFAQNov24.pdf'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '90%',
        borderRadius: 20,
    },

}));

export default function Healthcare2024Laya(row) {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <br />
            <h4 className={classes.marginLeft}>Dear Colleagues,</h4>
            <p>Please see below the recording from our recent Laya Healthcare Webinar</p>
            <p>Also we have attached two PDF documents so you can view those if you need more information</p>
            <br />
            <a href={IronMountainEmployeePresentation271124} download>Download Iron Mountain Employee Presentation.pdf</a>
            <br />
            <a href={IronMountainFAQNov24} download>Download Iron Mountain FAQ.pdf</a>
            <br />
            <br />
            <div className={classes.textCentered}>
                <video id='cookingDemo' className={classes.media} src={LayaHelathcareWebinar}
                    type="video/mp4"
                    // poster={cookingPoster}
                    controls
                    controlsList="nodownload">
                    Your browser does not support HTML video.
                </video>
            </div>

            <br />
            <br />
            <p>Regards,<br /><br />
                Brian Burke<br />
                HR Manager | Wisetek™<br />
            </p>
            <br />

        </div>
    )
}
